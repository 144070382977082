/**
 * Labstep
 *
 * @module components/JupyterNotebook/Action/Run
 * @desc Run a jupyter notebook
 */

import JupyterInstanceModalList from 'labstep-web/components/JupyterInstance/Modal/List';
import { withJupyterInstance } from 'labstep-web/containers/JupyterInstance';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React, { useEffect } from 'react';
import { JupyterNotebookPreviousRunProps } from './types';

export const JupyterNotebookPreviousRun: React.FC<
  JupyterNotebookPreviousRunProps
> = ({ jupyterNotebook, jupyterInstance, getJupyterInstance }) => {
  if (!jupyterNotebook.data) {
    return null;
  }

  useEffect(() => {
    getJupyterInstance('run', jupyterNotebook.id, true);
  }, []);

  let endedAt = null;
  if (jupyterInstance?.run[jupyterNotebook.id]?.ended_at) {
    endedAt = jupyterInstance?.run[jupyterNotebook.id]?.ended_at;
  }

  if (endedAt) {
    return (
      <>
        <JupyterInstanceModalList jupyterNotebook={jupyterNotebook} />
        <p>Last run: {humanReadableDate(endedAt)}</p>
      </>
    );
  }

  return null;
};

export default withJupyterInstance(JupyterNotebookPreviousRun);

/**
 * Labstep
 *
 * @module components/JupyterNotebook/Modal
 * @desc JupyterNotebook Modal
 */

import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import JupyterNotebookContent from '../Content';
import JupyterNotebookFormShowEditName from '../Form/ShowEdit/Name';
import { IJupyterNotebookModalProps } from './types';

export const JupyterNotebookModal: React.FC<
  IJupyterNotebookModalProps
> = ({ jupyterNotebook, viewComponent, onClose, open }) => (
  <Modal
    size="fullscreen"
    open={open}
    onClose={onClose}
    header={
      <JupyterNotebookFormShowEditName
        jupyterNotebook={jupyterNotebook}
      />
    }
    content={
      <JupyterNotebookContent jupyterNotebook={jupyterNotebook} />
    }
    viewComponent={viewComponent}
  />
);

export default JupyterNotebookModal;

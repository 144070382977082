/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Plan
 * @desc Organization plan card for plan
 */

import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import {
  ADDONS,
  premiumFeaturesInfo,
} from 'labstep-web/constants/premium-features';
import React from 'react';
import styles from './styles.module.scss';
import { OrganizationPlanAddonsProps } from './types';

export const OrganizationPlanAddons: React.FC<
  OrganizationPlanAddonsProps
> = ({ organization }) => {
  return (
    <OrganizationPlanCard
      icon="puzzle"
      header="Modules & Add-ons"
      secondary={
        <div className={styles.container}>
          {ADDONS.filter(
            (addon) => organization.premium_features?.includes(addon),
          ).map((addon) => {
            return (
              <div
                key={addon}
                data-testid="addon-label"
                className={styles.label}
              >
                <div>{premiumFeaturesInfo[addon].name}</div>
              </div>
            );
          })}
        </div>
      }
    />
  );
};

export default OrganizationPlanAddons;

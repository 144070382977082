/**
 * Labstep
 */

import { Modal } from 'semantic-ui-react';
import React from 'react';
import Header from 'labstep-web/core/Header';
import Flex from 'labstep-web/core/Flex';
import ModalWizardActions from '../Actions';
import { useModalWizardContext } from '../context';
import styles from './styles.module.scss';
import { IModalWizardStepProps } from './types';
import ModalWizardStepHelp from './Help';

export const ModalWizardStep: React.FC<IModalWizardStepProps> = ({
  index,
  description,
  children,
  help,
  hideBackButton,
  hideContinueButton,
  continueLabel,
  onContinue,
  onBack,
  actions,
  isContinueButtonDisabled,
  hideHeader,
}) => {
  const { activeStepIndex, steps } = useModalWizardContext();
  if (activeStepIndex !== index) {
    return null;
  }
  return (
    <>
      <Modal.Content className={styles.container}>
        <Flex column className={styles.content}>
          {!hideHeader && (
            <div>
              <Header>{steps[activeStepIndex]}</Header>
              <div className={styles.description}>{description}</div>
            </div>
          )}
          <>{children}</>
        </Flex>
        {help && <ModalWizardStepHelp help={help} />}
      </Modal.Content>
      <ModalWizardActions
        onContinue={onContinue}
        onBack={onBack}
        hideBackButton={hideBackButton}
        hideContinueButton={hideContinueButton}
        isContinueButtonDisabled={isContinueButtonDisabled}
        continueLabel={continueLabel}
        actions={actions}
      />
    </>
  );
};

export default ModalWizardStep;

import OrganizationInvoiceList from 'labstep-web/components/OrganizationInvoice/List';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IOrganizationPlanActionViewPaymentsProps } from './types';

export const OrganizationPlanActionViewPayments: React.FC<
  IOrganizationPlanActionViewPaymentsProps
> = ({ organization }) => {
  return organization.is_stripe_subscription_set ? (
    <ModalDefault
      viewComponent={
        <ActionComponent
          text="View Payments"
          type="button"
          elementProps={{ basic: true }}
        />
      }
      content={<OrganizationInvoiceList />}
    />
  ) : null;
};

export default OrganizationPlanActionViewPayments;

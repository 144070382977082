/**
 * Labstep
 *
 * @module models/jupyter-instance
 * @desc Typescript export class for JupyterInstance
 */

import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { JupyterNotebook } from 'labstep-web/models/jupyter-notebook.model';

// eslint-disable-next-line no-shadow
export enum JupyterInstanceType {
  edit = 'edit',
  run = 'run',
}

// eslint-disable-next-line no-shadow
export enum JupyterInstanceStatus {
  success = 'success',
  error = 'error',
}

export class JupyterInstance extends Entity {
  public static readonly idAttr = 'id' as const;

  static readonly entityName = 'jupyter_instance';

  get entityName(): string {
    return JupyterInstance.entityName;
  }

  public id!: string;

  type!: JupyterInstanceType;

  status!: JupyterInstanceStatus;

  started_at!: string;

  ended_at!: string;

  error_message!: string;

  @Type(() => JupyterNotebook)
  public jupyterNotebook!: JupyterNotebook;

  constructor(data: Partial<JupyterInstance> = {}) {
    super();
    Object.assign(this, data);
  }
}

/**
 * Labstep
 *
 * @module components/OrganizationPlan/Show
 * @desc Display organization billing plan
 */

import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { OrganizationPlan } from 'labstep-web/models/organization-plan.model';
import React from 'react';
import OrganizationPlanAddons from '../Card/Addons';
import OrganizationPlanCardNextPayment from '../Card/NextPayment';
import OrganizationPlanCardPaymentMethod from '../Card/PaymentMethod';
import OrganizationPlanCardPlan from '../Card/Plan';
import OrganizationPlanCardUsers from '../Card/Users';
import OrganizationPlanCardWorkspaces from '../Card/Workspaces';
import OrganizationPlanShowFreeTrial from './FreeTrial';
import styles from './styles.module.scss';
import {
  OrganizationPlanShowContainerProps,
  OrganizationPlanShowProps,
} from './types';

export const OrganizationPlanShow: React.FC<
  OrganizationPlanShowProps
> = ({ organization, organizationPlan }) => (
  <div className={styles.container} id="organization-billing-plan">
    <OrganizationPlanCardPlan
      organization={organization}
      organizationPlan={organizationPlan}
    />
    <OrganizationPlanAddons organization={organization} />
    <OrganizationPlanCardUsers organization={organization} />
    <OrganizationPlanCardWorkspaces organization={organization} />
    <OrganizationPlanCardNextPayment
      organization={organization}
      organizationPlan={organizationPlan}
    />
    <OrganizationPlanCardPaymentMethod
      organization={organization}
      organizationPlan={organizationPlan}
    />
  </div>
);

export const OrganizationPlanShowContainer: React.FC<
  OrganizationPlanShowContainerProps
> = ({ organization }) => (
  <ReadOnMountHOC
    type="entity"
    id={organization.id}
    entityName={OrganizationPlan.entityName}
    loading={{ cached: true, loader: 'placeholder', multiplier: 1 }}
    children={({ entity: organizationPlan }) =>
      organization.is_trialling || !organizationPlan ? (
        <OrganizationPlanShowFreeTrial organization={organization} />
      ) : (
        <OrganizationPlanShow
          organization={organization}
          organizationPlan={organizationPlan}
        />
      )
    }
  />
);

export default OrganizationPlanShowContainer;

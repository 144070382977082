import { PremiumFeaturesConfig } from './types';

export const Config: PremiumFeaturesConfig = {
  filters: {
    molecule: {
      premium_feature: 'chemistry',
      type: 'show',
    },
    resource: {
      premium_feature: 'inventory',
      type: 'hide',
    },
  },
  metadata: {
    molecule: {
      premium_feature: 'chemistry',
      type: 'show',
    },
    sequence: {
      premium_feature: 'molecular_biology',
      type: 'show',
    },
  },
  bulk_metadata: {
    molecule: {
      premium_feature: 'chemistry',
      type: 'hide',
    },
    sequence: {
      premium_feature: 'molecular_biology',
      type: 'hide',
    },
  },
  meatadata_detail_table: {
    device: {
      premium_feature: 'devices',
      type: 'hide',
    },
  },
  referencing_menu_categories: {
    resource: {
      premium_feature: 'inventory',
      type: 'hide',
    },
    device: {
      premium_feature: 'devices',
      type: 'hide',
    },
  },
  conditions_table: {
    protocol_value: {
      premium_feature: 'inventory',
      type: 'hide',
    },
  },
  global_search: {
    resource: {
      premium_feature: 'inventory',
      type: 'hide',
    },
    resource_item: {
      premium_feature: 'inventory',
      type: 'hide',
    },
  },
};

/**
 * Labstep
 *
 * @module prosemirror/components/Menu/Commands/Modal/Add
 * @desc A modal to add elements
 */

import React from 'react';
import ExperimentWorkflowActionAddProtocol from 'labstep-web/components/ExperimentWorkflow/Action/AddProtocol';
import ProtocolTimerActionCreateForm from 'labstep-web/components/ProtocolTimer/Action/Create/Form';
import ProtocolDeviceActionCreate from 'labstep-web/components/ProtocolDevice/Action/Create';
import FileActionCreate from 'labstep-web/components/File/Action/Create';
import ModalDefault from 'labstep-web/core/Modal/Default';
import FormReusable from 'labstep-web/core/Form/Reusable';
import CodeMirror from 'labstep-web/core/CodeMirror/Modal';
import ExperimentWorkflowActionLinkExperimentWorkflow from 'labstep-web/components/ExperimentWorkflow/Action/LinkExperimentWorkflow';
import MenuCommandsModalHtmlTable from 'labstep-web/prosemirror/components/Menu/Commands/Modal/HtmlTable';
import { getToken } from 'labstep-web/prosemirror/utils/selection';
import { replaceWithLink } from 'labstep-web/prosemirror/marks/link/commands';
import { replaceWithCode } from 'labstep-web/prosemirror/marks/code/commands';
import { replaceWithNode } from 'labstep-web/prosemirror/nodes/commands';
import { handleSubmitElement } from 'labstep-web/prosemirror/extensions/slash/commands';
import { TRIGGER } from 'labstep-web/prosemirror/extensions/slash/plugin';
import { replaceWithTable } from 'labstep-web/prosemirror/nodes/table/commands';
import { replaceWithReference } from 'labstep-web/prosemirror/nodes/reference/commands';
import { useProtocolConditionModalContext } from 'labstep-web/components/ProtocolCondition/Modal/context';
import { fields } from 'labstep-web/prosemirror/marks/link';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { PremiumFeatureModal } from 'labstep-web/core/PremiumFeature/Modal';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { IMenuCommandsModalAddProps } from './types';
import { updateFile } from './utils';

const MenuCommandsModalAdd: React.FC<IMenuCommandsModalAddProps> = ({
  state,
  dispatch,
  view,
  entity,
  experimentWorkflow,
  submittedElement,
}) => {
  const { setIsOpen } = useProtocolConditionModalContext();
  if (!submittedElement) {
    return null;
  }
  if (submittedElement.id === 'conditions') {
    setIsOpen(entity);
    return null;
  }
  const token = getToken(state, TRIGGER);
  const onSuccess = ({ response }) => {
    const nodeKey = submittedElement.key;
    const id = Array.isArray(response.result)
      ? response.result[0]
      : response.result;

    if (submittedElement.key) {
      replaceWithNode(
        state,
        dispatch,
        token.from,
        token.to,
        nodeKey,
        { [getIdAttribute(nodeKey)]: id },
        null,
      );
    }
    view.focus();
  };

  const defaultProps = {
    modalProps: {
      open: true,
      onClose: () => handleSubmitElement(state, dispatch, null),
      viewComponent: () => null,
    },
  };

  let modal = null;

  if (
    submittedElement.premium_feature &&
    !useHasFeatureFlagEnabled(submittedElement.premium_feature)
  ) {
    return (
      <PremiumFeatureModal
        premiumFeature={submittedElement.premium_feature}
        {...defaultProps.modalProps}
      />
    );
  }

  switch (submittedElement.id) {
    case 'experiment_workflow_link':
      modal = (
        <ExperimentWorkflowActionLinkExperimentWorkflow
          experimentWorkflow={experimentWorkflow}
          options={{ onSuccess }}
          {...defaultProps}
        />
      );
      break;
    case 'protocol_collection':
      modal = (
        <ExperimentWorkflowActionAddProtocol
          experimentWorkflow={experimentWorkflow}
          onSuccess={onSuccess}
          isModal
          {...defaultProps}
        />
      );
      break;
    case 'protocol_timer':
      modal = (
        <ProtocolTimerActionCreateForm
          protocol={entity}
          options={{ onSuccess }}
          {...defaultProps}
        />
      );
      break;
    case 'file':
    case 'image': {
      modal = (
        <FileActionCreate
          entityType={entity.entityName}
          entityId={entity.id}
          options={{ onSuccess, toast: true }}
          onFolderWatcherSelect={({ file }) =>
            updateFile(entity, file, { onSuccess })
          }
          {...defaultProps}
        />
      );
      break;
    }
    case 'link': {
      const onSubmit = ({ url, text }: any) => {
        replaceWithLink(
          state,
          dispatch,
          token.from,
          token.to,
          url,
          text,
        );
        view.focus();
      };
      modal = (
        <ModalDefault
          header="Add Link"
          content={() => (
            <FormReusable
              onSubmit={onSubmit}
              fields={fields}
              autoFocus
            />
          )}
          {...defaultProps.modalProps}
        />
      );
      break;
    }
    case 'code': {
      const onSubmit = ({ type, description }) => {
        replaceWithCode(
          state,
          dispatch,
          token.from,
          token.to,
          type,
          description,
        );
        view.focus();
      };
      modal = (
        <CodeMirror
          status={null}
          onSubmit={onSubmit}
          {...defaultProps}
        />
      );
      break;
    }
    case 'html_table': {
      const onSubmit = ({ rows, columns }: any) =>
        replaceWithTable(
          state,
          dispatch,
          token.from,
          token.to,
          rows,
          columns,
        );
      modal = (
        <MenuCommandsModalHtmlTable
          onSubmit={onSubmit}
          modalProps={defaultProps.modalProps}
        />
      );
      break;
    }
    case 'protocol_device': {
      modal = (
        <ProtocolDeviceActionCreate
          parent={entity}
          options={{
            onSuccess: ({ response }) => {
              replaceWithReference(
                state,
                dispatch,
                token.from,
                token.to,
                {
                  guid: response.result,
                  entityName: ProtocolDevice.entityName,
                },
              );
            },
          }}
          {...defaultProps}
        />
      );
      break;
    }
    default:
      break;
  }

  return modal;
};

export default MenuCommandsModalAdd;

import { PremiumFeature } from 'labstep-web/models/organization.model';

interface PremiumFeatureInfo {
  link?: string;
  name: string;
  selling_points?: string[];
  image?: string;
  title?: string;
}

export const ADDONS: PremiumFeature[] = [
  'inventory',
  'devices',
  'ordering',
  'molecular_biology',
  'chemistry',
  'jupyter',
];

export const premiumFeaturesInfo: Record<
  PremiumFeature,
  PremiumFeatureInfo
> = {
  jupyter: {
    name: 'Jupyter Notebooks',
    link: 'https://help.labstep.com/en/articles/6463715-getting-started-with-jupyter-notebooks',
    title: 'Automate with Integrated Jupyter Notebooks',
    selling_points: [
      'Fully-Featured Interactive Jupyter Notebook Editor',
      'Execute custom python code within Labstep protocols and experiments with a single click',
      'Automate data analysis and visualisation',
      'All notebooks pre-loaded with the Labstep SDK for custom automations',
    ],
    image: '/img/premium-features/jupyter.gif',
  },
  ip_whitelist: {
    name: 'IP Whitelist',
    link: 'https://help.labstep.com/en/articles/4699892-adding-ip-whitelisting-to-your-account',
  },
  '2fa': {
    name: 'Two-factor authentication',
    link: 'https://help.labstep.com/en/articles/4699786-adding-two-factor-authentication-to-your-account',
  },
  security_log: {
    name: 'Security Logs',
  },
  export_logs: {
    name: 'Exports Logs',
  },
  custom_permissions: {
    name: 'Custom Permissions',
  },
  time_machine: {
    name: 'Time Machine',
  },
  timeline: {
    name: 'Timeline',
  },
  chemistry: {
    name: 'Chemistry',
    selling_points: [
      'Draw chemical structures with best-in-class chemical drawing tools Ketcher and MarvinJS',
      'Pull-in structures automatically by searching the PubChem database directly from within Labstep.',
      'Automatically populate metadata such as IUPAC name, CAS Number, Molecular Weight, Molecular Formula and Density from PubChem along with hazard pictograms and safety codes.',
      'Add reaction schemes to experiments and pull-in pre-defined chemical structures from your inventory.',
      'Built-in stoichiometry tables for automating calculations.',
    ],
    link: 'https://help.labstep.com/en/articles/5739332-using-the-chemistry-tools-on-labstep',
    image: '/img/premium-features/chemistry.png',
  },
  auto_sharing: {
    name: 'Auto Sharing',
  },
  sso: {
    name: 'Single sign-on',
  },
  multiplexing: {
    name: 'Variables & Conditions',
    title: 'Structure data with Variables and Conditions',
    selling_points: [
      'Execute protocols on multiple samples in parallel',
      'Automatically link data and track lineage for multiple samples or different conditions in parallel.',
      'Keep data structured for downstream analysis',
    ],
    link: 'https://help.labstep.com/en/articles/6708126-using-variables-fields-and-multiple-conditions',
    image: '/img/premium-features/conditions_tables.gif',
  },
  entity_import: {
    name: '',
  },
  marvinjs: {
    name: 'MarvinJS Chemistry Editor',
  },
  lock_settings: {
    name: 'Advanced Locking',
  },
  signature_workflow: {
    name: 'Status Workflows',
    title:
      'Enforce Processes with Custom Status and Signature Workflows',
    selling_points: [
      'Define custom statuses to track the progress of your experiments at a more granular level. ',
      'Create Custom Collaborator roles such as Reviewer or Approver and define how many need to be assigned at which stage.',
      'Enforce which roles need to sign at each stage before progressing. ',
      'Restrict allowed actions for different statuses with Comment Only mode',
      'Improve visibility and compliance',
    ],
    link: 'https://help.labstep.com/en/articles/9317493-configuring-status-workflows',
    image: '/img/premium-features/status_workflows.gif',
  },
  inventory: {
    name: 'Inventory',
    title: 'Full-featured Inventory and Sample Management',
    selling_points: [
      'Keep track of all your reagents and samples down to the batch or aliquot level',
      'Create categories with customisable metadata templates',
      'Set stock alerts and expiry date notifications',
      'Track lineage and link experimental data automatically',
      'Define location hierarchies and use QR codes to streamline workflows',
    ],
    link: 'https://help.labstep.com/en/collections/8588481-lesson-3-the-inventory',
    image: '/img/premium-features/item-view.png',
  },
  ordering: {
    name: 'Order Management',
    selling_points: [
      'Track the progress of order requests as they are approval, ordered and received ',
      'Define required fields for requests with custom metadata templates',
      'Automatically populate inventory when items are received',
      'Get custom notifications when requests are updated ',
      'Group requests into Orders and track the status and price of the whole order',
    ],
    link: 'https://help.labstep.com/en/collections/3557009-order-management',
    image: '/img/premium-features/ordering.gif',
  },
  devices: {
    name: 'Device Management',
    selling_points: [
      'Streamline data capture with Universal Device Client',
      'Manage bookings with built-in calendar',
      'Track device metadata such as model and serial numbers as well as user manuals and calibration certificates',
      'Alerts for service / calibration dates',
      'Setup custom integrations for devices using the Labstep API',
    ],
    link: 'https://help.labstep.com/en/collections/3557000-instruments-devices',
    image: '/img/premium-features/devices.gif',
  },
  molecular_biology: {
    name: 'Molecular Biology',
    selling_points: [
      'Add interactive plasmid sequence maps to resources, viewable and editable in our built-in Molecular Biology tool suite.',
      'Annotate with cutsites, primer binding sites, open reading frames and other features',
      'Import / Export .gb files with complete sequence annotations',
      'Simulate plasmid digestion with restriction enzymes',
      'Simulate sequence translation of parts',
    ],
    link: 'https://help.labstep.com/en/collections/3557000-instruments-devices',
    image: '/img/premium-features/devices.gif',
  },
  custom_identifier: {
    name: 'Custom Identifiers',
    selling_points: [
      'Use custom identifiers for experiments and / or resources to simplify naming',
      'Define a custom prefix per workspace / category',
      'Auto-incrementing number for each new experiment or resource',
    ],
    image: '/img/premium-features/custom_identifiers.gif',
  },
  completion_checklist: {
    name: 'Completion Checklist',
    image: '/img/premium-features/completion_checklist.gif',
    selling_points: [
      'Ensure required fields are completed before allowing protocols / experiments to be completed',
      'Alert users to incomplete fields',
    ],
  },
  spreadsheet_designer: {
    name: 'Spreadsheet Designer',
  },
  export: {
    name: 'Export',
  },
};

import { useMemo } from 'react';
import { useHasFeatureFlagsEnabled } from 'labstep-web/hooks/feature-flag';
import { Config } from 'labstep-web/config/premium-feature';
import { Source } from 'labstep-web/config/premium-feature/types';

const getConfigForKeys = (keys: string[], source: Source) => {
  const configSource = Config[source];

  return keys.map((key) => {
    return {
      key,
      config: configSource[key],
    };
  });
};

/**
 * Custom hook to determine the visibility and status of premium features for a list of options.
 *
 * @template T - The type of objects in the options array.
 * @template K - The key within each option object that is used to match with the premium feature configuration.
 *
 * @param {T[]} options - Array of objects representing the options. Each object must include the key specified by `keyName`.
 * @param {K} keyName - The key in each option object to match against the premium feature configuration.
 * @param {Source} source - The source type for configuration.
 *
 * @returns {Array<T & { premiumFeature?: PremiumFeature; show: boolean; premiumFeatureEnabled: boolean; }>}
 * - Returns only the options that should be shown, extended with premium feature details.
 */
export const usePremiumFeatureStatus = <
  T extends Record<string, any>,
  K extends keyof T,
>(
  options: T[],
  keyName: K,
  source: Source,
) => {
  const keys = options.map((option) => option[keyName]);

  const keysWithConfig = useMemo(
    () => getConfigForKeys(keys, source),
    [keys, source],
  );

  const premiumFeatures = keysWithConfig
    .map((keyConfig) => keyConfig.config?.premium_feature)
    .filter((feature) => feature !== undefined);

  const featureFlags = useHasFeatureFlagsEnabled(premiumFeatures);

  const optionsWithConfig = useMemo(() => {
    return options.map((option) => {
      const featureConfig = keysWithConfig.find(
        (c) => c.key === option[keyName],
      )?.config;

      if (!featureConfig) {
        return {
          ...option,
          premiumFeature: undefined,
          show: true,
          premiumFeatureEnabled: true,
        };
      }

      const { premium_feature, type } = featureConfig;

      return {
        ...option,
        premiumFeature: premium_feature,
        show:
          type === 'show' || featureFlags[premium_feature] === true,
        premiumFeatureEnabled: featureFlags[premium_feature],
      };
    });
  }, [options, keysWithConfig, featureFlags]);

  return optionsWithConfig.filter((o) => o.show);
};

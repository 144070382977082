/**
 * Labstep
 *
 * @module models/jupyter-notebook
 * @desc Typescript export class for JupyterNotebook
 */

import { Entity } from 'labstep-web/models/entity.model';

export class JupyterNotebook extends Entity {
  public static readonly idAttr = 'id' as const;

  static readonly entityName = 'jupyter_notebook';

  get entityName(): string {
    return JupyterNotebook.entityName;
  }

  get label(): string {
    return this.name || 'Untitled Notebook';
  }

  public id!: string;

  name!: string;

  data!: Record<string, unknown>;

  static icon = '/img/jupyter-notebook/python.svg';

  constructor(data: Partial<JupyterNotebook> = {}) {
    super();
    Object.assign(this, data);
  }
}

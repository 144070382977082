/**
 * Labstep
 *
 * @module components/JupyterInstance/List
 * @desc JupyterInstance List
 */

import Table from 'labstep-web/core/Table';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { JupyterInstance } from 'labstep-web/models/jupyter-instance.model';
import { humanReadableDate } from 'labstep-web/services/date.service';
import React from 'react';
import { JupyterInstanceListProps } from './types';

export const JupyterInstanceList: React.FC<
  JupyterInstanceListProps
> = ({ jupyterNotebook }) => (
  <ReadOnMountHOC
    type="cursor"
    entityName={JupyterInstance.entityName}
    params={{
      is_ended: true,
      jupyter_notebook_id: jupyterNotebook.id,
    }}
    loading={{ loader: 'main_content', cached: true }}
    children={({ entities }) => (
      <Table striped unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={2}>Start date</Table.Cell>
            <Table.Cell width={2}>End date</Table.Cell>
            <Table.Cell width={2}>Status</Table.Cell>
            <Table.Cell width={2}>Error message</Table.Cell>
          </Table.Row>
          {entities.map((jupyterInstance: JupyterInstance) => (
            <Table.Row key={jupyterInstance.id}>
              <Table.Cell width={2}>
                {humanReadableDate(jupyterInstance.started_at)}
              </Table.Cell>
              <Table.Cell width={2}>
                {humanReadableDate(jupyterInstance.ended_at)}
              </Table.Cell>
              <Table.Cell width={2}>
                {jupyterInstance.status}
              </Table.Cell>
              <Table.Cell width={2}>
                {jupyterInstance.error_message}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )}
  />
);

export default JupyterInstanceList;

/**
 * Labstep
 *
 * @module components/Filter/Menu
 * @desc Filters menu
 */

import { useEntitySearchContext } from 'labstep-web/components/Entity/Search/context';
import ExperimentWorkflowFilterProtocol from 'labstep-web/components/ExperimentWorkflow/Filter/Protocol/Select';
import ExperimentWorkflowFilterResource from 'labstep-web/components/ExperimentWorkflow/Filter/Resource/Select';
import ExperimentWorkflowFilterSigned from 'labstep-web/components/ExperimentWorkflow/Filter/Signed/Select';
import ExperimentWorkflowFilterStatus from 'labstep-web/components/ExperimentWorkflow/Filter/Status/Select';
import ExperimentWorkflowFilterStatusType from 'labstep-web/components/ExperimentWorkflow/Filter/StatusType/Select';
import FileFilterExtensionSelect from 'labstep-web/components/File/Filter/Extension/Select';
import FilterAssignedTo from 'labstep-web/components/Filter/AssignedTo';
import FilterAvailableResourceItemCount from 'labstep-web/components/Filter/AvailableResourceItemCount';
import FilterCreatedBy from 'labstep-web/components/Filter/CreatedBy';
import FilterDateRange from 'labstep-web/components/Filter/DateRange';
import FilterDeleted from 'labstep-web/components/Filter/Deleted';
import FilterMetadata from 'labstep-web/components/Filter/Metadata';
import FilterResourceItemStatus from 'labstep-web/components/Filter/ResourceItemStatus';
import FilterTag from 'labstep-web/components/Filter/Tag/Select';
import MoleculeFilter from 'labstep-web/components/Molecule/Filter';
import PurchaseOrderFilterStatus from 'labstep-web/components/PurchaseOrder/Filter/Status/Select';
import ResourceItemFilterResourceLocation from 'labstep-web/components/ResourceItem/Filter/ResourceLocation';
import Dropdown from 'labstep-web/core/Dropdown';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import { ICONS } from 'labstep-web/constants/icons';
import {
  Filter,
  IFilterMenuContentProps,
  IFilterMenuProps,
} from './types';
import { FilterMenuList } from './List';

export const FilterMenuContent: React.FC<IFilterMenuContentProps> = ({
  setParams,
  filters,
  searchParams,
  entityName,
}) => {
  const hasSignatureFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );

  const filterMenuContent = [
    {
      name: Filter.deleted,
      component: (
        <FilterDeleted
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.is_user_deleted,
      component: (
        <FilterDeleted
          setParams={setParams}
          searchParams={searchParams}
          paramKey="is_user_deleted"
        />
      ),
    },
    {
      name: Filter.is_group_deleted,
      component: (
        <FilterDeleted
          setParams={setParams}
          searchParams={searchParams}
          paramKey="is_group_deleted"
        />
      ),
    },
    {
      name: Filter.date_range,
      component: (
        <FilterDateRange
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.resource_item_status,
      component: (
        <FilterResourceItemStatus
          isOrderRequest={filters.includes(
            Filter.order_request_status,
          )}
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.order_request_status,
      component: (
        <FilterResourceItemStatus
          isOrderRequest={filters.includes(
            Filter.order_request_status,
          )}
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.available_resource_item_count,
      component: (
        <FilterAvailableResourceItemCount
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.extension,
      component: (
        <FileFilterExtensionSelect
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.created_by,
      component: (
        <FilterCreatedBy
          searchParams={searchParams}
          setParams={setParams}
          filter={Filter.created_by}
        />
      ),
    },
    {
      name: Filter.requested_by,
      component: (
        <FilterCreatedBy
          searchParams={searchParams}
          setParams={setParams}
          filter={Filter.requested_by}
        />
      ),
    },
    {
      name: Filter.assigned_to,
      component: (
        <FilterAssignedTo
          searchParams={searchParams}
          setParams={setParams}
        />
      ),
    },
    {
      name: Filter.tag,
      component: (
        <FilterTag
          setParams={setParams}
          searchParams={searchParams}
          type={entityName}
        />
      ),
    },
    {
      name: Filter.protocol,
      component: (
        <ExperimentWorkflowFilterProtocol
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.resource,
      component: (
        <ExperimentWorkflowFilterResource
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
      actionComponentProps: {
        text: 'Resource used',
        icon: ICONS.resource.primary,
      },
    },
    {
      name: Filter.signed,
      component: (
        <ExperimentWorkflowFilterSigned
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.experiment_workflow_status,
      component: hasSignatureFeatureEnabled ? (
        <ExperimentWorkflowFilterStatus
          setParams={setParams}
          searchParams={searchParams}
        />
      ) : null,
    },
    {
      name: Filter.experiment_workflow_status_type,
      component: (
        <ExperimentWorkflowFilterStatusType
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.purchase_order_status,
      component: (
        <PurchaseOrderFilterStatus
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.resource_location,
      component: (
        <ResourceItemFilterResourceLocation
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
    },
    {
      name: Filter.molecule,
      component: (
        <MoleculeFilter
          setParams={setParams}
          searchParams={searchParams}
        />
      ),
      actionComponentProps: {
        text: 'Chemical Structure',
        icon: ICONS.molecule.primary,
      },
    },
  ];

  return (
    <FilterMenuList
      filtersContent={filterMenuContent}
      filters={filters}
    />
  );
};

export const FilterMenuContentPostFilter: React.FC<
  IFilterMenuContentProps
> = ({ filters, setParams, searchParams, addPostFilter }) => {
  const filterMenuContent = [
    {
      name: Filter.deleted,
      component: (
        <FilterDeleted
          setParams={setParams}
          searchParams={searchParams}
          addPostFilter={addPostFilter}
        />
      ),
    },
    {
      name: Filter.metadata,
      component: (
        <FilterMetadata
          entityName={ResourceItem.entityName}
          addPostFilter={addPostFilter}
        />
      ),
    },
    {
      name: Filter.resource_metadata,
      component: (
        <FilterMetadata
          entityName={ResourceItem.entityName}
          childEntityName={Resource.entityName}
          addPostFilter={addPostFilter}
        />
      ),
    },
    {
      name: Filter.resource_item_status,
      component: (
        <FilterResourceItemStatus
          isOrderRequest={false}
          setParams={setParams}
          searchParams={searchParams}
          addPostFilter={addPostFilter}
        />
      ),
    },
    {
      name: Filter.created_by,
      component: (
        <FilterCreatedBy
          searchParams={searchParams}
          setParams={setParams}
          filter={Filter.created_by}
          addPostFilter={addPostFilter}
        />
      ),
    },
    {
      name: Filter.assigned_to,
      component: (
        <FilterAssignedTo
          searchParams={searchParams}
          setParams={setParams}
          addPostFilter={addPostFilter}
        />
      ),
    },
    {
      name: Filter.resource_location,
      component: (
        <ResourceItemFilterResourceLocation
          setParams={setParams}
          searchParams={searchParams}
          addPostFilter={addPostFilter}
        />
      ),
    },
  ];
  return (
    <FilterMenuList
      filtersContent={filterMenuContent}
      filters={filters}
    />
  );
};

const FilterMenu: React.FC<IFilterMenuProps> = ({
  trigger,
  ...rest
}) => {
  const { searchParams, setParams, addPostFilter } =
    useParamsContext();
  const { usePostFilter } = useEntitySearchContext();
  return (
    <Dropdown
      data-testid="filter-menu"
      icon={null}
      trigger={trigger}
      pointing="top right"
    >
      {usePostFilter ? (
        <FilterMenuContentPostFilter
          searchParams={searchParams}
          setParams={setParams}
          addPostFilter={addPostFilter}
          {...rest}
        />
      ) : (
        <FilterMenuContent
          searchParams={searchParams}
          setParams={setParams}
          addPostFilter={addPostFilter}
          {...rest}
        />
      )}
    </Dropdown>
  );
};

export default FilterMenu;
